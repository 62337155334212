import { Stack, Typography } from "knack-ui";
import { useHistory } from "react-router-dom";
import { PageTitleHeight } from "../../../lib/constants";
import { useUser } from "../../User/userQueries";
import ReportTitledCard from "../components/ReportTitledCard";

const AnalyticsReportPage = () => {
  const history = useHistory();
  const { data: user } = useUser();

  return (
    <div className="relative min-h-full border rounded-lg bg-paper">
      <div
        style={{
          borderTop: 0,
          borderRight: 0,
          borderLeft: 0,
          height: PageTitleHeight,
          zIndex: 20
        }}
        className="sticky top-0 flex items-center justify-between px-6 py-4 border bg-paper"
      >
        <Stack alignItems="center" justifyContent="space-between" className="w-full">
          <Typography as="h5" variant="h6">
            Reports
          </Typography>
        </Stack>
      </div>

      <div className="p-6 bg-paper">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <ReportTitledCard
            icon="Smile"
            onView={() => {
              history.push("/reports/user");
            }}
            title="Users report"
          />
          <ReportTitledCard
            icon="Users"
            onView={() => {
              history.push("/reports/pairings");
            }}
            title="Pairing report"
          />
          <ReportTitledCard
            icon="Sessions"
            onView={() => {
              history.push("/reports/sessions");
            }}
            title="Sessions report"
          />
          <ReportTitledCard
            icon="Goals"
            onView={() => {
              history.push("/reports/goals");
            }}
            title="Goals report"
          />

          {user?.account_type === "Admin" && (
            <>
              <ReportTitledCard
                icon="CircleDollar"
                onView={() => {
                  history.push("/reports/payment-management");
                }}
                title="Payment Management"
              />
              <ReportTitledCard
                icon="Users"
                onView={() => {
                  history.push("/reports/active-users");
                }}
                title="Active Users"
              />
               <ReportTitledCard
                icon="Work"
                onView={() => {
                  history.push("/reports/miscellaneous");
                }}
                title="Miscellaneous"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsReportPage;