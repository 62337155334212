import { Box, Breadcrumbs, Typography } from "knack-ui";
import { Link, useParams } from "react-router-dom";
import ConnectionProfileHeader from "./ui/ConnectionProfileHeader";
import { Tab, Tabs, TabList } from "react-tabs";
import { useGetConnectionProfile } from "../../../features/Connections/connectionsQueries";
import { lazy, useMemo, useState } from "react";
import { loadComponent } from "../../../lib/utils";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import { AnimatePresence, Variants } from "framer-motion";
import { useUser, useUserRoles } from "../../../features/User/userQueries";

const LazyConnectionDetails = lazy(() => loadComponent(() => import("./ui/ConnectionDetails")));
const LazyConnectionEnrolledPrograms = lazy(() => loadComponent(() => import("./ui/ConnectionEnrolledPrograms")));
const LazyConnectionSessions = lazy(() => loadComponent(() => import("./ui/ConnectionSessions")));
const LazyConnectionGoals = lazy(() => loadComponent(() => import("./ui/ConnectionGoals")));
const LazyConnectionAssessment = lazy(() => loadComponent(() => import("./ui/ConnectionAssessment")));

const ConnectionDetails = withSuspense(withErrorBoundary(LazyConnectionDetails), {
  fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
});
const ConnectionEnrolledPrograms = withSuspense(withErrorBoundary(LazyConnectionEnrolledPrograms), {
  fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
});
const ConnectionSessions = withSuspense(withErrorBoundary(LazyConnectionSessions), {
  fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
});
const ConnectionGoals = withSuspense(withErrorBoundary(LazyConnectionGoals), {
  fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
});
const ConnectionAssessment = withSuspense(withErrorBoundary(LazyConnectionAssessment), {
  fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
});

export const ConnectionPageTransitions: Variants = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  exit: {
    y: 200,
    opacity: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.3
    }
  }
};

export const ConnectionTabHeight = "calc(100vh - 360px)";
const ConnectionProfile = () => {
  const { id } = useParams<{ id?: string }>();
  const { data: profile } = useGetConnectionProfile({ id });
  const { roleState } = useUserRoles();
  const [tab, setTab] = useState(0);
  const { data: user } = useUser();

  const canShowAssessment = useMemo(() => {
    if (user?.account_type === "Coach") {
      return true;
    } else if (
      roleState?.activeRole === "Expert" &&
      profile?.assessment_report_visible_to &&
      profile?.assessment_report_visible_to.includes(user?._id as string)
    ) {
      return true;
    }
    return false;
  }, [profile, roleState, user]);

  return (
    <div>
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4 ">
        {[
          <Link key="link" to={user?.account_type !== "Coach" ? "/connections" : "/clients"}>
            {user?.account_type !== "Coach" ? "Connections" : "Clients"}
          </Link>,
          <Typography key="name" clamp={1}>
            {profile?.first_name} {profile?.last_name} 
          </Typography>
        ]}
      </Box>
      <Box
        // To make tailwind rtl plugin work
        dir="ltr"
        variant="outlined"
      >
        <ConnectionProfileHeader />
        <Tabs
          style={{
            overflowY: "hidden",
            overflowX: "hidden"
          }}
          selectedIndex={tab}
          onSelect={(tab) => setTab(tab)}
        >
          <TabList className="px-4 overflow-x-hidden border-b">
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Profile
            </Tab>
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Programs
            </Tab>
            <Tab key="sessions" className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Sessions
            </Tab>
            {roleState?.activeRole !== "User" && (
              <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
                Goals
              </Tab>
            )}
            {canShowAssessment && (
              <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
                Assessment
              </Tab>
            )}
          </TabList>

          <AnimatePresence exitBeforeEnter>
            {tab === 0 && <ConnectionDetails />}
            {tab === 1 && <ConnectionEnrolledPrograms />}
            {tab === 2 && <ConnectionSessions />}
            {tab === 3 && roleState?.activeRole !== "User" && <ConnectionGoals />}
            {tab === 4 && canShowAssessment && <ConnectionAssessment />}
          </AnimatePresence>
        </Tabs>
      </Box>
    </div>
  );
};

export default withErrorBoundary(ConnectionProfile);
